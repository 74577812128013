// eslint-disable-next-line no-redeclare,no-unused-vars
function showSettings() {
  dashboard.show();

  // develop config
  if (config == 'develop') {
    ajax({
      object: 'settings',
      objectSub: 'settingsClient',
      func: 'buildSettingsView',
      detail: true,
      keepDetail: true,
    });
    if (industry)
      ajax({
        object: 'settings',
        objectSub: 'settingsIndustry',
        func: 'buildSettingsView',
        detail: true,
        keepDetail: true,
      });
    ajax({
      object: 'settings',
      objectSub: 'settingsGlobal',
      func: 'buildSettingsView',
      detail: true,
      keepDetail: true,
    });
  }

  // branch config
  else {
    ajax({
      object: 'settings',
      send: {func: 'buildConfigTree'},
      func: 'buildSettingsView',
      detail: true,
      keepDetail: true,
      title: config,
      keepFunc: true,
    });
    ajax({
      object: 'settings',
      send: {func: 'buildConfigTree', merged: true},
      func: 'buildSettingsView',
      detail: true,
      keepDetail: true,
      title: config + '.json merged',
      keepFunc: true,
    });
  }
}
